@import "./button";
@import "./animations";
@import "./responsive";
@import "./modal";


body {
  font-family: "Open Sans", sans-serif;
  color: #263248;
}

.padding-header-top {
  padding-top: 67px;
}

.isMobile {
  display: none;
}

.isDesktop {
  display: block;
}

//Common

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.homePage,
.org__list {
  .dropdown-display-value {
    height: 43px;
  }
}

.container-mobile {
  padding: 30px;
}

.notice-text {
  font-size: .8rem;
  font-style: italic;
}

//No data
.block-nodata {
  flex-direction: column;

  padding: 35px 0;
  gap: 5px;

  img {
    width: 100px;
    height: 100px;
  }

  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  span {
    font-size: calc((14/16)*1rem);
  }
}

/* Define Scroll Broser */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f2f2f2;
  background-color: #dbdbdb;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  border: 2px solid #BDBDBD;
  border-radius: 5px;
}

//Display 
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//Table 
.table-style,
.table-style-2 {
  background-color: var(--white);
  border: 1px solid var(--table-border);
  border-radius: 5px;
  -webkit-animation: .5s ease-out .3s both fadeIn;
  animation: .5s ease-out .3s both fadeIn;
  margin-bottom: 30px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  vertical-align: middle;
}

.table {
  tbody>tr {
    border-bottom: 1px solid #edf2fa;

    td {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.header-hide-scroll {
  display: none;
}

.up {
  color: #06a53b !important;
}

.pledge {
  color: orange !important;
}

.down {
  color: red !important;
}

.no-items-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.no-items {
  background-repeat: no-repeat;
  width: 143px;
  height: 98px;
  background-size: cover;
  border: none;
  margin-bottom: 10px;
}

.no-items-loading {
  background-image: url(https://fma.dev.techland.link/trade/assets/images/icon/loading.gif);
}

.no-items-loaded {
  background-image: url(https://fma.dev.techland.link/trade/assets/images/icon/nodata.png);
}

.no-items-title {
  font: 16px OpenSans6;
  color: $black;
  margin: 35px 0 10px;
}

.no-items-message {
  font-size: 14px;
  color: $tab-text;
}

.gold__content {
  table {
    border-collapse: separate;
    border-spacing: 0;

    max-width: 714px;
    width: 100%;

    margin: 20px 0 30px;

    tr {

      th {
        font-weight: 400;
        border: 1px solid #DBDBDB;
        padding: 14px 25px;
      }

      td {
        border: 1px solid #DBDBDB;
        font-weight: 600;
        padding: 14px 25px;
      }

      &:first-child {
        th {
          border-right: 0;
          border-top-left-radius: 10px;
        }

        td {
          border-top-right-radius: 10px;
        }
      }

      &:last-child {
        th {
          border-top: 0;
          border-right: 0;
          border-bottom-left-radius: 10px;
        }

        td {
          border-top: 0;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.action__group {
  iframe {
    z-index: 9 !important;
  }
}

#fundPopup {
  .modal-dialog {
    max-width: 1200px;
  }
}

.no-scroll {
  overflow: hidden !important;
}

#label-new-product {
  background-color: #11ab62;
  color: white !important;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 5px;
  margin-bottom: 0;
  position: relative;

  height: 24px;

  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #11ab62 transparent;
    z-index: 9;
    transform: rotate(270deg);
  }
}
.en-text {
  font-size: 18px;
  margin-top: 5px;
  color: #4e4b51;
  font-style: italic;
}